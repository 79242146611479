import React from 'react'
import Layout from '../components/Layout/Layout'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import Banner from '../Images/Pics/ContactUs.jpeg'
import '../styles/HomeStyles.css'
import Marquee from 'react-fast-marquee'

const ContactUs = () => {
  return (
    <Layout>
      <div className='home1' style={{ backgroundImage: `url(${Banner})` }}>
        {' '}
      </div>{' '}
      <Container
        sx={{
          my: 0,
          textAlign: 'center',
          p: 2,
          '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
          '& p': { textAlign: 'justify' },
          '@media (max-width:600px)': {
            mt: 0,
            '& h4': {
              fontSize: '1rem',
              my: 0,
            },
          },
        }}
      >
        <Typography variant='h4'> CONTACT US </Typography> <br />{' '}
        <Marquee pauseOnHover speed={80} direction='right'>
          <div className='headerContainer1'>
            {' '}
            <h1> Contact Address of Head Office and other: </h1>{' '}
          </div>{' '}
        </Marquee>{' '}
        <Card
          style={{ maxWidth: 500, margin: '50px auto', padding: '20px 5px' }}
        >
          <CardContent>
            <Typography variant='h6' gutterBottom>
              {' '}
              REQUEST A QUOTE{' '}
            </Typography>{' '}
            <Typography gutterBottom variant='body3' component='p'>
              Please fill up the Inquiry Form.We will contact you within 2
              working hours.{' '}
            </Typography>{' '}
            <FormControl>
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <TextField
                    label='Contact Persons Name'
                    placeholder='Contact Persons Name'
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Grid>{' '}
                <Grid xs={12} item>
                  <TextField
                    type='email'
                    label='Email'
                    placeholder='Enter email'
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Grid>{' '}
                <Grid xs={12} item>
                  <TextField
                    type='number'
                    label='Contact Number'
                    placeholder='Enter phone number'
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Grid>{' '}
                <Grid xs={12} item>
                  <TextField
                    label='Company Name'
                    placeholder='Company Name'
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Grid>{' '}
                <Grid xs={12} item>
                  <TextField
                    label='Your Inquiry'
                    multiline
                    rows={4}
                    placeholder='Type your message'
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Grid>{' '}
                <Grid xs={12} item>
                  <Button
                    type='submit' //variant='contained' fullWidth>
                    variant='contained'
                    size='large'
                  >
                    {' '}
                    Submit{' '}
                  </Button>{' '}
                </Grid>{' '}
              </Grid>{' '}
            </FormControl>{' '}
          </CardContent>{' '}
        </Card>{' '}
        <br /> <br /> {/* <Box sx={{ width: '100%' }}> </Box>{' '} */}{' '}
        <Box className='Contact'>
          <Grid container spacing={2} direction='column'>
            <Grid item container spacing={4} xs={12} sm={12} md={4} lg={3}>
              <Grid item xs={6}>
                <Typography
                  variant='h5'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {' '}
                  Register Office Address:{' '}
                </Typography>{' '}
                <p>
                  M / s.MADHYA BHARAT SECURITY SERVICE, BEHIND JAI BABA KIRANA
                  Ward No .65, Behind Jai Baba Kirana Store, Mahadev Ghat Road,
                  Ashwani Nagar, Raipur(C.G.), PIN: 492013.{' '}
                </p>{' '}
              </Grid>{' '}
              <Grid item xs={3}>
                <Typography
                  variant='h5'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {' '}
                  Contact Number:{' '}
                </Typography>{' '}
                <p> Land Line: 0771 - 2243022 </p>{' '}
                <p> Mobile: 9826697763, 9425509322 </p>{' '}
              </Grid>{' '}
              <Grid item xs={3}>
                <Typography
                  variant='h5'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {' '}
                  Email:{' '}
                </Typography>{' '}
                <p> mbssraipur @gmail.com </p>{' '}
              </Grid>{' '}
            </Grid>{' '}
          </Grid>{' '}
          <br />
          <Grid container spacing={2} direction='column'>
            <Grid item xs={12} container spacing={4}>
              <Grid item xs={6}>
                <Typography
                  variant='h5'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {' '}
                  Head Office Address:{' '}
                </Typography>{' '}
                <p>
                  C / o Mr.Deepak Deewan, Near Goswami Kirana Store, Post -
                  Sundar Nagar, Hanuman Nagar, Raipur(C.G.) PIN - 492013.{' '}
                </p>{' '}
              </Grid>{' '}
              <Grid item xs={3}>
                <Typography
                  variant='h5'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {' '}
                  Contact Number:{' '}
                </Typography>{' '}
                <p> Mobile: 9826697763, 9425509322 </p>{' '}
              </Grid>{' '}
              <Grid item xs={3}>
                <Typography
                  variant='h5'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {' '}
                  Email:{' '}
                </Typography>{' '}
                <p> mbssraipur @gmail.com </p>{' '}
              </Grid>{' '}
            </Grid>{' '}
          </Grid>{' '}
          <br />
          <Grid container spacing={2} direction='column'>
            <Grid item xs={12} container spacing={4}>
              <Grid item xs={6}>
                <Typography
                  variant='h5'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {' '}
                  Branch Office Address:{' '}
                </Typography>{' '}
                <p>
                  C / o Mr.Sanjay Bharat(Advocate), Near HP Petrol Pump, Station
                  Road, Bishrampur, Distt. - Surajpur(C.G.).{' '}
                </p>{' '}
              </Grid>{' '}
              <Grid item xs={3}>
                <Typography
                  variant='h5'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {' '}
                  Contact Number:{' '}
                </Typography>{' '}
                <p> Mobile: 6267596471, 9993009014 </p>{' '}
              </Grid>{' '}
              <Grid item xs={3}>
                <Typography
                  variant='h5'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {' '}
                  Email:{' '}
                </Typography>{' '}
                <p> mbssraipur @gmail.com </p>{' '}
              </Grid>{' '}
            </Grid>{' '}
          </Grid>{' '}
          <br />
          <Grid container spacing={2} direction='column'>
            <Grid item xs={12} container spacing={4}>
              <Grid item xs={6}>
                <Typography
                  variant='h5'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {' '}
                  Branch Office Address:{' '}
                </Typography>{' '}
                <p>
                  Gaurella, Pendra, Marwahi, Main Road Basantpur(Pendra)
                  Chhattisgarh.{' '}
                </p>{' '}
              </Grid>{' '}
              <Grid item xs={3}>
                <Typography
                  variant='h5'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {' '}
                  Contact Number:{' '}
                </Typography>{' '}
                <p> Mobile: 7354618666 </p>{' '}
              </Grid>{' '}
              <Grid item xs={3}>
                <Typography
                  variant='h5'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {' '}
                  Email:{' '}
                </Typography>{' '}
                <p> mbssraipur @gmail.com </p>{' '}
              </Grid>{' '}
            </Grid>{' '}
          </Grid>{' '}
          <br />
          <Grid container spacing={2} direction='column'>
            <Grid item xs={12} container spacing={4}>
              <Grid item xs={6}>
                <Typography
                  variant='h5'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {' '}
                  Branch Office Address:{' '}
                </Typography>{' '}
                <p> Jharsuguda Road, Rengali, Dist.Sambalpur Odisha. </p>{' '}
              </Grid>{' '}
              <Grid item xs={3}>
                <Typography
                  variant='h5'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {' '}
                  Contact Number:{' '}
                </Typography>{' '}
                <p> Mobile: 9425509322 </p>{' '}
              </Grid>{' '}
              <Grid item xs={3}>
                <Typography
                  variant='h5'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {' '}
                  Email:{' '}
                </Typography>{' '}
                <p> mbssraipur @gmail.com </p>{' '}
              </Grid>{' '}
            </Grid>{' '}
          </Grid>{' '}
        </Box>{' '}
      </Container>{' '}
    </Layout>
  )
}

export default ContactUs
