import React from 'react'
import Layout from '../components/Layout/Layout'
import { Container, Typography } from '@mui/material'
import Banner from '../Images/Pics/Whyus2.jfif'

const Whyus = () => {
  return (
    <Layout>
      {' '}
      <div className='home1' style={{ backgroundImage: `url(${Banner})` }}>
        {' '}
      </div>{' '}
      <Container
        Container
        sx={{
          my: 0,
          textAlign: 'center',
          p: 2,
          '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
          '& p': { textAlign: 'justify' },
          '@media (max-width:600px)': {
            mt: 0,
            '& h4': {
              fontSize: '1rem',
              my: 0,
            },
          },
        }}
      >
        <Typography variant='h4'> WHY US </Typography> <br />
        <p className='Home-heading'>
          Our security personnel will perform duties on the basis of six days a
          week in 08 hours shift.Reliever staff will be provided by us at your
          cost on the day.Reliever charges will be claimed 1 / 6 of the wages.{' '}
        </p>{' '}
        <br />
        <p className='Home-heading'>
          In case of the theft case or misplacement of any materials from the
          premises we will undertake thorough enquiry(Joint inspection report)
          in the matter and if the materials is not traceable we will make good
          the loss if incurred due to negligence of our personnel.{' '}
        </p>{' '}
        <br />
        <p className='Home-heading'>
          Security post, security lights, emergency lights, fencing wall, fire
          alarm, and proper Gates will be provided by you.{' '}
        </p>{' '}
      </Container>{' '}
    </Layout>
  )
}

export default Whyus
