import React from 'react'
import Layout from '../components/Layout/Layout'
import Banner from '../Images/Pics/Training.jpeg'
import { Container, Typography } from '@mui/material'

const Training = () => {
  return (
    <Layout>
      <div className='home1' style={{ backgroundImage: `url(${Banner})` }}>
        {' '}
      </div>{' '}
      <Container
        sx={{
          my: 0,
          textAlign: 'center',
          p: 2,
          '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
          '& p': { textAlign: 'justify' },
          '@media (max-width:600px)': {
            mt: 0,
            '& h4': {
              fontSize: '1rem',
              my: 0,
            },
          },
        }}
      >
        <Typography variant='h4'> TRAINING </Typography> <br />
        <p className='Home-heading'>
          {' '}
          After appointment all the candidates undergo a rigorous training for a
          month by highly trained professionals who have retired from top posts
          in military, police and fire service departments.They are given
          theoretical and practical lesions on basic guarding skills, rudiments
          on first aid, firefighting and self - defense.They are also given
          classes on courtesy, obedience, mannerism, fire prevention, disaster
          management and human psychology, to name a few.{' '}
        </p>{' '}
      </Container>{' '}
    </Layout>
  )
}

export default Training
