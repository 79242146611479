import React, { useState } from 'react'
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  Divider,
} from '@mui/material'
// import SecurityIcon from '@mui/icons-material/Security'
import { NavLink } from 'react-router-dom'
import '../../styles/HeaderStyles.css'
import MenuIcon from '@mui/icons-material/Menu'
// import Logo from '../../Images/New Photos/Logo.svg.jpg'
import Logo from '../../Images/New Photos/Logo.svg.jpg'

function Header() {
  const [mobileOpen, setMobileOpen] = useState(false)

  // handle menu click
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  //menu drawer
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      {' '}
      <Typography
        //color={'goldenrod'}
        color={'#2962ff'}
        variant='h4'
        component='div'
        sx={{ flexGrow: 1, my: 2, fontSize: '1rem' }}
      >
        {/* <SecurityIcon sx={{ mr: 1 }} />{' '} */}{' '}
        <div>
          {' '}
          {/* <img
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    className='logo'
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    src='https://scontent-bom1-1.xx.fbcdn.net/v/t39.30808-6/395423378_6773039369452432_5719265542421118680_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=qlOabakpyNEAX9TlUc9&_nc_ht=scontent-bom1-1.xx&oh=00_AfCBFiCEQU5CAX7raw6XOvOYqFHzFrJeO793Qzpp-I1dAA&oe=653DD61F'
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    alt=''
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  /> */}{' '}
          <img className='logo' src={Logo} alt='logo' />
        </div>
        MADHYA BHARAT SECURITY SERVICE{' '}
      </Typography>{' '}
      <Divider />
      <ul className='mobile-navigation'>
        <li>
          <NavLink activeClassName='active' to={'/'}>
            {' '}
            Home{' '}
          </NavLink>{' '}
        </li>{' '}
        <li>
          <NavLink to={'/about'}> About Us </NavLink>{' '}
        </li>{' '}
        <li>
          <NavLink to={'/securityservices'}> Security Services </NavLink>{' '}
        </li>{' '}
        <li>
          <NavLink to={'/training'}> Training </NavLink>{' '}
        </li>{' '}
        <li>
          <NavLink to={'/whyus'}> Why Us </NavLink>{' '}
        </li>{' '}
        <li>
          <NavLink to={'/clients'}> Our Clients </NavLink>{' '}
        </li>{' '}
        <li>
          <NavLink to={'/contactus'}> Contact Us </NavLink>{' '}
        </li>{' '}
      </ul>{' '}
    </Box>
  )
  return (
    <>
      <Box>
        <AppBar
          className='Appbar'
          component={'nav'}
          sx={{
            bgcolor: 'iniherit',

            // zIndex: '100',
            // '@media (max-width:600px)': {
            // position: 'fixed',
            //   zIndex: '100',
            //   top: '0',
            // },
          }}
        >
          <Toolbar>
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              //aria-label='open drawer'

              sx={{ mr: 2, display: { sm: 'none' } }}
              onClick={handleDrawerToggle}
            >
              {' '}
              <MenuIcon />{' '}
            </IconButton>{' '}
            <div>
              {' '}
              {/* <img
                                                                                                                                                                                                                                                                                                                                                                className='logo'
                                                                                                                                                                                                                                                                                                                                                                src='https://scontent-bom1-1.xx.fbcdn.net/v/t39.30808-6/395423378_6773039369452432_5719265542421118680_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=qlOabakpyNEAX9TlUc9&_nc_ht=scontent-bom1-1.xx&oh=00_AfCBFiCEQU5CAX7raw6XOvOYqFHzFrJeO793Qzpp-I1dAA&oe=653DD61F'
                                                                                                                                                                                                                                                                                                                                                                alt=''
                                                                                                                                                                                                                                                                                                                                                              /> */}{' '}
              <img className='logo' src={Logo} alt='logo' />
            </div>{' '}
            <Typography
              //color={'goldenrod'}
              color={'white'}
              variant='h5'
              component='div'
              sx={{ flexGrow: 1, my: 2 }}
            >
              {/* <SecurityIcon sx={{ mr: 1 }} />  */}
              MADHYA BHARAT SECURITY SERVICE{' '}
            </Typography>{' '}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <ul className='navigation-menu'>
                <li>
                  <NavLink activeClassName='active' to={'/'}>
                    {' '}
                    HOME{' '}
                  </NavLink>{' '}
                </li>{' '}
                <li>
                  <NavLink to={'/about'}> ABOUT US </NavLink>{' '}
                </li>{' '}
                <li>
                  <NavLink to={'/securityservices'}>
                    {' '}
                    SECURITY SERVICES{' '}
                  </NavLink>{' '}
                </li>{' '}
                <li>
                  <NavLink to={'/training'}> TRAINING </NavLink>{' '}
                </li>{' '}
                <li>
                  <NavLink to={'/whyus'}> WHY US </NavLink>{' '}
                </li>{' '}
                <li>
                  <NavLink to={'/clients'}> OUR CLIENTS </NavLink>{' '}
                </li>{' '}
                <li>
                  <NavLink to={'/contactus'}> CONTACT US </NavLink>{' '}
                </li>{' '}
              </ul>{' '}
            </Box>{' '}
          </Toolbar>{' '}
        </AppBar>{' '}
        <Box component={'nav'}>
          <Drawer
            variant='temporary'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: '240px',
              },
            }}
          >
            {' '}
            {drawer}{' '}
          </Drawer>{' '}
        </Box>{' '}
        {/* </Box><Box sx={{ p: 3 }}> */}{' '}
        <Box>
          <Toolbar />{' '}
        </Box>{' '}
      </Box>{' '}
    </>
  )
}

export default Header
