import React from 'react'
import { Box, Grid, Typography, colors } from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram'
import TwitterIcon from '@mui/icons-material/Twitter'
import YouTubeIcon from '@mui/icons-material/YouTube'
import FacebookIcon from '@mui/icons-material/Facebook'
import { Link } from 'react-router-dom'
// import { styled } from '@mui/material/styles'
// import { Link } from 'react-router-dom'
import '../../styles/FooterStyles.css'

function Footer() {
  return (
    <Box>
      <Box
        sx={{ textAlign: 'center', bgcolor: '#1A1A19', color: 'white', p: 0 }}
      >
        <Box
          className='Footer-box'
          sx={{
            background: colors.blue,

            p: { xs: 4, md: 10 },
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{ textAlign: 'justify' }}
            >
              <Typography variant='h5' sx={{ margin: '15px' }}>
                ABOUT US{' '}
              </Typography>{' '}
              <Typography
                variant='h7'
                sx={{
                  marginLeft: '30px',
                }}
              >
                <p
                  className='para'
                  // sx={{
                  //   '@media (max-width:600px)': {
                  //     marginLeft: '20px',
                  //   },
                  // }}
                >
                  We, Madhya Bharat Security Service introduce ourselves as one
                  of the premier security agency of Raipur Chhattisgarh.We are
                  registered as Private Security Agency by Deptt of Home,
                  Government of Raipur Chhattisgarh under Private Security
                  Agency Regulation Act, 2005.{' '}
                </p>{' '}
              </Typography>{' '}
            </Grid>{' '}
            <Grid
              className='Footer-grid'
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              // sx={{
              //   '@media (max-width:600px)': {
              //     marginRight: '170px',
              //   },
              // }}
            >
              <Typography variant='h5' sx={{ margin: '15px' }}>
                QUICK LINKS{' '}
              </Typography>{' '}
              <Link to='/About' size='small' className='Footer-links'>
                {' '}
                Mission / Vision{' '}
              </Link>{' '}
              <br /> <br />
              <Link to='/Training' size='small' className='Footer-links'>
                Training{' '}
              </Link>{' '}
              <br />
              <br />
              <Link to='/Whyus' size='small' className='Footer-links'>
                {' '}
                Why Us{' '}
              </Link>{' '}
              <br /> <br />
              <Link to='/Clients' size='small' className='Footer-links'>
                {' '}
                Our Clients{' '}
              </Link>{' '}
              <br /> <br />
              <Link to='/Contactus' size='small' className='Footer-links'>
                {' '}
                Contact Us{' '}
              </Link>{' '}
            </Grid>{' '}
            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ textAlign: 'left' }}>
              <Typography variant='h5' sx={{ margin: '15px' }}>
                SECURITY SERVICES{' '}
              </Typography>{' '}
              <Typography variant='h6' sx={{ margin: '15px' }}>
                Manpower Services{' '}
              </Typography>{' '}
              <Typography variant='h6' sx={{ margin: '15px' }}>
                Office Security Services{' '}
              </Typography>{' '}
              <Typography variant='h6' sx={{ margin: '15px' }}>
                Bouncer Security Services{' '}
              </Typography>{' '}
              <Typography variant='h6' sx={{ margin: '15px' }}>
                {' '}
                Residence Security Services{' '}
              </Typography>{' '}
              <Typography variant='h6' sx={{ margin: '15px' }}>
                Hospital Security Services{' '}
              </Typography>{' '}
            </Grid>{' '}
            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ textAlign: 'start' }}>
              <Typography variant='h5' sx={{ margin: '15px' }}>
                QUICK CONTACT{' '}
              </Typography>{' '}
              <Typography variant='h6' sx={{ margin: '15px' }}>
                Register Office Address:
              </Typography>{' '}
              <Typography
                sx={{ margin: '15px', textAlign: 'justify', fontSize: '13px' }}
              >
                MADHYA BHARAT SECURITY SERVICE, Ward No .65, Behind Jai Baba
                Kirana Store, Mahadev Ghat Road, Ashwani Nagar, Raipur(C.G.),
                PIN: 492013,{' '}
              </Typography>{' '}
              <Typography variant='h6' sx={{ margin: '15px' }}>
                {' '}
                Contact Number:
              </Typography>{' '}
              <Typography variant='h8' sx={{ margin: '15px' }}>
                Land Line: 0771 - 2243022{' '}
              </Typography>{' '}
              <br />
              <Typography variant='h8' sx={{ margin: '15px' }}>
                Mobile: 9826697763 <br />
              </Typography>{' '}
              <Typography variant='h8' sx={{ margin: '15px' }}>
                Mobile: 9425509322{' '}
              </Typography>{' '}
              <Typography variant='h6' sx={{ margin: '15px' }}>
                {' '}
                E - mail:
              </Typography>{' '}
              <Typography variant='h8' sx={{ margin: '15px' }}>
                mbssraipur @gmail.com{' '}
              </Typography>{' '}
            </Grid>{' '}
          </Grid>{' '}
        </Box>{' '}
        <Box
          sx={{
            my: 2,
            '& svg': { fontSize: '60px', cursor: 'pointer', mr: 2 },
            '& svg:hover': {
              color: 'goldenrod',
              transform: 'translateX(5px)',
              transition: 'all 400ms',
              textAlign: 'center',
            },
          }}
        >
          {' '}
          {/* icon coding*/} <InstagramIcon />
          <TwitterIcon />
          <YouTubeIcon />
          <FacebookIcon />
        </Box>{' '}
        <Typography
          variant='h5'
          sx={{ '@media (max-width:600px)': { fontSize: '1rem' } }}
        >
          {' '}
          All Rights Reserved | ©Vedsi Company{' '}
          {/* All Rights Reserved & copy Vedsi Company{' '} */}{' '}
        </Typography>{' '}
      </Box>{' '}
      {/* extra Coding*/}{' '}
    </Box>
  )
}

export default Footer
