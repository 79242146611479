import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import About from './pages/About'
import Clients from './pages/Clients'
import ContactUs from './pages/ContactUs'
import Training from './pages/Training'
import Whyus from './pages/Whyus'
import Pagenotfound from './pages/Pagenotfound'
import SecurityServices from './pages/SecurityServices'
import ScrollToTop from './components/ScrollToTop.jsx'

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />{' '}
          <Route path='/about' element={<About />} />{' '}
          <Route path='/clients' element={<Clients />} />{' '}
          <Route path='/contactus' element={<ContactUs />} />{' '}
          <Route path='/securityservices' element={<SecurityServices />} />{' '}
          <Route path='/training' element={<Training />} />{' '}
          <Route path='/whyus' element={<Whyus />} />{' '}
          <Route path='*' element={<Pagenotfound />} />{' '}
        </Routes>{' '}
        <ScrollToTop />
      </BrowserRouter>{' '}
    </div>
  )
}

export default App
