import React from 'react'
import Layout from '../components/Layout/Layout'
import { Container, Typography, Box } from '@mui/material'
import Banner from '../Images/Pics/Client.jpeg'
import '../styles/Clients.css'
import Marquee from 'react-fast-marquee'

import img1 from '../Images/Clients/Adani.png'
import img2 from '../Images/Clients/Gaurav Electricals.png'
import img3 from '../Images/Clients/Venika Hydro Projects Pvt Ltd..png'
import img4 from '../Images/Clients/APL APOLLO TUBES LTD..jfif'
import img5 from '../Images/Clients/NTPC.png'
import img6 from '../Images/Clients/Bajaj Township.png'
import img7 from '../Images/Clients/R K Transport & Construction Ltd..png'
import img8 from '../Images/Clients/Rajat Buildtech Pvt. Ltd..png'
import img9 from '../Images/Clients/Shaksham Food Industries.png'
import img10 from '../Images/Clients/U B Ventures Pvt Ltd..png'
import img11 from '../Images/Clients/VGR Real Estate (Metro Green’s).png'
import img12 from '../Images/Clients/BVG INDIA LIMITED.jfif'
import img13 from '../Images/Clients/Chhugani Pride.jfif'
import img14 from '../Images/Clients/Excel Education Centre.jfif'
import img15 from '../Images/Clients/IBC24 Media Pvt. Ltd..jfif'
import img16 from '../Images/Clients/IND SYNERGY LIMITED.jfif'
import img17 from '../Images/Clients/Jayaswal Neco Industries Limited.png'
import img18 from '../Images/Clients/K D HOSPITAL.jfif'
import img19 from '../Images/Clients/Monnet.jfif'
import img20 from '../Images/Clients/New Leaf Enterprise Pvt. Ltd..jfif'
import img21 from '../Images/Clients/OM Multi Specialty Hospital.jfif'
import img22 from '../Images/Clients/Patanjali Mega Store.jfif'
import img23 from '../Images/Clients/R R Ispat (Hira Group).jfif'
import img24 from '../Images/Clients/Shivani Constructions.jfif'
import img25 from '../Images/Clients/Shyam Metalics & Energy Limited.jfif'
import img26 from '../Images/Clients/Tata Motors.jfif'
import img27 from '../Images/Clients/Vandana Group.jfif'
import img28 from '../Images/Clients/VISA Power Limited.jfif'

const Clients = () => {
  return (
    <Layout>
      <div className='home1' style={{ backgroundImage: `url(${Banner})` }}>
        {' '}
      </div>{' '}
      <Container
        Container
        sx={{
          my: 0,
          textAlign: 'center',
          p: 2,
          '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
          '& p': { textAlign: 'justify' },
          '@media (max-width:600px)': {
            mt: 0,
            '& h4': {
              fontSize: '1rem',
              my: 0,
            },
          },
        }}
      >
        <Typography variant='h4'> OUR CLIENTS </Typography> <br />
        <Marquee pauseOnHover speed={80} direction='right'>
          <div className='headerContainer1'>
            {' '}
            <h1> The Esteem Clients of our Company are </h1>{' '}
          </div>{' '}
        </Marquee>{' '}
        <br /> <br />
        <Box
          sx={{
            width: '100%',
            alignContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              alignContent: 'center',
            }}
          >
            <div className='row'>
              <div className='Client-img' style={{ marginRight: '15px' }}>
                <img src={img1} alt='' />
              </div>{' '}
              <div className='Client-img' style={{ marginRight: '15px' }}>
                <img src={img2} alt='' />{' '}
              </div>{' '}
              <div className='Client-img' style={{ marginRight: '15px' }}>
                <img src={img3} alt='' />{' '}
              </div>{' '}
              <div className='Client-img' style={{ marginRight: '15px' }}>
                <img src={img4} alt='' />{' '}
              </div>{' '}
              <div className='Client-img' style={{ marginRight: '15px' }}>
                <img src={img5} alt='' />{' '}
              </div>{' '}
              <div className='Client-img' style={{ marginRight: '5px' }}>
                <img src={img6} alt='' />{' '}
              </div>{' '}
            </div>{' '}
          </Box>{' '}
          <br />
          <Box sx={{ width: '100%', alignContent: 'center' }}>
            <div className='row'>
              <div
                className='Client-img'
                style={{ marginRight: '15px', marginLeft: '15px' }}
              >
                <img src={img7} alt='' />{' '}
              </div>{' '}
              <div className='Client-img' style={{ marginRight: '15px' }}>
                <img src={img8} alt='' />{' '}
              </div>{' '}
              <div className='Client-img' style={{ marginRight: '15px' }}>
                <img src={img9} alt='' />{' '}
              </div>{' '}
              <div className='Client-img' style={{ marginRight: '15px' }}>
                <img src={img10} alt='' />{' '}
              </div>{' '}
            </div>{' '}
          </Box>{' '}
          <br />
          <br />
          <Box sx={{ width: '100%', alignContent: 'center' }}>
            <div className='row'>
              <div className='Client-img' style={{ marginRight: '5px' }}>
                <img src={img11} alt='' />{' '}
              </div>{' '}
              <div
                className='Client-img'
                style={{ marginRight: '15px', marginLeft: '15px' }}
              >
                <img src={img12} alt='' />{' '}
              </div>{' '}
              <div className='Client-img' style={{ marginRight: '15px' }}>
                <img src={img13} alt='' />{' '}
              </div>{' '}
              <div className='Client-img' style={{ marginRight: '15px' }}>
                <img src={img14} alt='' />{' '}
              </div>{' '}
            </div>{' '}
          </Box>{' '}
          <br />
          <br />
          <Box sx={{ width: '100%', alignContent: 'center' }}>
            <div className='row'>
              <div className='Client-img' style={{ marginRight: '15px' }}>
                <img src={img15} alt='' />{' '}
              </div>{' '}
              <div className='Client-img' style={{ marginRight: '5px' }}>
                <img src={img16} alt='' />{' '}
              </div>{' '}
              <div
                className='Client-img'
                style={{ marginRight: '15px', marginLeft: '15px' }}
              >
                <img src={img17} alt='' />{' '}
              </div>{' '}
              <div className='Client-img' style={{ marginRight: '15px' }}>
                <img src={img18} alt='' />{' '}
              </div>{' '}
              <div className='Client-img' style={{ marginRight: '15px' }}>
                <img src={img19} alt='' />{' '}
              </div>{' '}
              <div className='Client-img' style={{ marginRight: '15px' }}>
                <img src={img20} alt='' />{' '}
              </div>{' '}
            </div>{' '}
          </Box>{' '}
          <br />
          <br />
          <Box sx={{ width: '100%', alignContent: 'center' }}>
            <div className='row'>
              <div className='Client-img' style={{ marginRight: '5px' }}>
                <img src={img21} alt='' />{' '}
              </div>{' '}
              <div
                className='Client-img'
                style={{ marginRight: '15px', marginLeft: '15px' }}
              >
                <img src={img22} alt='' />{' '}
              </div>{' '}
              <div className='Client-img' style={{ marginRight: '15px' }}>
                <img src={img23} alt='' />{' '}
              </div>{' '}
              <div className='Client-img' style={{ marginRight: '15px' }}>
                <img src={img24} alt='' />{' '}
              </div>{' '}
            </div>{' '}
          </Box>{' '}
          <br />
          <br />
          <Box sx={{ width: '100%', alignContent: 'center' }}>
            <div className='row'>
              <div className='Client-img' style={{ marginRight: '15px' }}>
                <img src={img25} alt='' />{' '}
              </div>{' '}
              <div className='Client-img' style={{ marginRight: '5px' }}>
                <img src={img26} alt='' />{' '}
              </div>{' '}
              <div
                className='Client-img'
                style={{ marginRight: '15px', marginLeft: '15px' }}
              >
                <img src={img27} alt='' />{' '}
              </div>{' '}
              <div className='Client-img' style={{ marginRight: '15px' }}>
                <img src={img28} alt='' />{' '}
              </div>{' '}
            </div>{' '}
          </Box>{' '}
        </Box>{' '}
      </Container>{' '}
    </Layout>
  )
}

export default Clients
