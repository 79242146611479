import React from 'react'
import Layout from '../components/Layout/Layout'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Grid, Container, CardActions, CardActionArea } from '@mui/material'
import Banner from '../Images/Pics/SecurityServices.jpeg'
// import Data from './Data.json'
import { Link } from 'react-router-dom'
import '../styles/HomeStyles.css'

import Card1 from '../Images/Pics/Card1.jpeg'
import Card2 from '../Images/Pics/Card2.jpeg'
import Card3 from '../Images/Pics/Card3.jpeg'
import Card4 from '../Images/Pics/Card4.jpeg'
import Card5 from '../Images/Pics/Card5.jpeg'
import Card6 from '../Images/Pics/Card6.jpeg'
import Card7 from '../Images/Pics/Card7.jpeg'
import Card8 from '../Images/Pics/Card8.jpeg'
import Card9 from '../Images/Pics/Card9.jpeg'

const SecurityServices = () => {
  return (
    <Layout>
      <div className='home1' style={{ backgroundImage: `url(${Banner})` }}>
        {' '}
      </div>{' '}
      <Container
        Container
        sx={{
          my: 0,
          textAlign: 'center',
          p: 2,
          '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
          '& p': { textAlign: 'justify' },
          '@media (max-width:600px)': {
            mt: 0,
            '& h4': {
              fontSize: '1rem',
              my: 0,
            },
          },
        }}
      >
        <Typography variant='h4'> OUR SECURITY SERVICES </Typography> <br />
        <p className='Home-heading'>
          {' '}
          We would provide the excellent Security Service to the satisfaction of
          your management and full Security coverage in your site.We are
          committed to quality and laid out standard.And we maintain statutory
          records and follow the rules of security services as per State
          Govt.notification.Our origination’ s function is run by proper channel
          and security service guide lines with an absolute command and control
          structure.Our field officers are well experienced in the art of
          discipline.We have with us the trained and expert field officers,
          staffs and supervisors.Our officers regularly visit the sites to
          ensure better functioning and communication.{' '}
        </p>{' '}
        <br /> {/* card material ui */}{' '}
        <Grid
          container
          spacing={5}
          style={{
            marginTop: '20px',
            className: 'CardPic',
            marginLeft: '5px',
            textAlign: 'center',
            '@media (max-width:600px)': {
              mt: 0,
              '& h4': {
                fontSize: '0.7rem',
                width: '100%',
                marginLeft: 5,
                paddingLeft: 7,
              },
            },
          }}
        >
          <Grid xs={12} md={4} sm={4} className='CardPic'>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component='img'
                  height='400'
                  image={Card1}
                  alt='Gaurd'
                />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Armed Gaurds Gun Man{' '}
                  </Typography>{' '}
                  <Typography variant='body2' color='black'>
                    {' '}
                  </Typography>{' '}
                </CardContent>{' '}
              </CardActionArea>{' '}
              <CardActions>
                <Link to='/SecurityServices' size='small'>
                  {' '}
                  Know More{' '}
                </Link>{' '}
              </CardActions>{' '}
            </Card>{' '}
          </Grid>{' '}
          <Grid xs={12} md={4} sm={4} className='CardPic'>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component='img'
                  height='400'
                  image={Card2}
                  alt='Gaurd'
                />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Un - Armed Gaurds{' '}
                  </Typography>{' '}
                  <Typography variant='body2' color='black'>
                    {' '}
                  </Typography>{' '}
                </CardContent>{' '}
              </CardActionArea>{' '}
              <CardActions>
                <Link to='/SecurityServices' size='small'>
                  {' '}
                  Know More{' '}
                </Link>{' '}
              </CardActions>{' '}
            </Card>{' '}
          </Grid>{' '}
          <Grid xs={12} md={4} sm={4} className='CardPic'>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component='img'
                  height='400'
                  image={Card3}
                  alt='Gaurd'
                />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Security Gaurds / Caretakers{' '}
                  </Typography>{' '}
                  <Typography variant='body2' color='black'>
                    {' '}
                  </Typography>{' '}
                </CardContent>{' '}
              </CardActionArea>{' '}
              <CardActions>
                <Link to='/SecurityServices' size='small'>
                  {' '}
                  Know More{' '}
                </Link>{' '}
              </CardActions>{' '}
            </Card>{' '}
          </Grid>{' '}
          <Grid xs={12} md={4} sm={4} className='CardPic'>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component='img'
                  height='400'
                  image={Card4}
                  alt='Gaurd'
                />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Security Supervisor / Officer{' '}
                  </Typography>{' '}
                  <Typography variant='body2' color='black'>
                    {' '}
                  </Typography>{' '}
                </CardContent>{' '}
              </CardActionArea>{' '}
              <CardActions>
                <Link to='/SecurityServices' size='small'>
                  {' '}
                  Know More{' '}
                </Link>{' '}
              </CardActions>{' '}
            </Card>{' '}
          </Grid>{' '}
          <Grid xs={12} md={4} sm={4} className='CardPic'>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component='img'
                  height='400'
                  image={Card5}
                  alt='Gaurd'
                />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    House - Keeping Staff{' '}
                  </Typography>{' '}
                  <Typography variant='body2' color='black'>
                    {' '}
                  </Typography>{' '}
                </CardContent>{' '}
              </CardActionArea>{' '}
              <CardActions>
                <Link to='/SecurityServices' size='small'>
                  {' '}
                  Know More{' '}
                </Link>{' '}
              </CardActions>{' '}
            </Card>{' '}
          </Grid>{' '}
          <Grid xs={12} md={4} sm={4} className='CardPic'>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component='img'
                  height='400'
                  image={Card6}
                  alt='Gaurd'
                />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Office Boy / Peon{' '}
                  </Typography>{' '}
                  <Typography variant='body2' color='black'>
                    {' '}
                  </Typography>{' '}
                </CardContent>{' '}
              </CardActionArea>{' '}
              <CardActions>
                <Link to='/SecurityServices' size='small'>
                  {' '}
                  Know More{' '}
                </Link>{' '}
              </CardActions>{' '}
            </Card>{' '}
          </Grid>{' '}
          <Grid xs={12} md={4} sm={4} className='CardPic'>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component='img'
                  height='400'
                  image={Card7}
                  alt='Gaurd'
                />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Data Entry Operators{' '}
                  </Typography>{' '}
                  <Typography variant='body2' color='black'>
                    {' '}
                  </Typography>{' '}
                </CardContent>{' '}
              </CardActionArea>{' '}
              <CardActions>
                <Link to='/SecurityServices' size='small'>
                  {' '}
                  Know More{' '}
                </Link>{' '}
              </CardActions>{' '}
            </Card>{' '}
          </Grid>{' '}
          <Grid xs={12} md={4} sm={4} className='CardPic'>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component='img'
                  height='400'
                  image={Card8}
                  alt='Gaurd'
                />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Official / Clerical Staff{' '}
                  </Typography>{' '}
                  <Typography variant='body2' color='black'>
                    {' '}
                  </Typography>{' '}
                </CardContent>{' '}
              </CardActionArea>{' '}
              <CardActions>
                <Link to='/SecurityServices' size='small'>
                  {' '}
                  Know More{' '}
                </Link>{' '}
              </CardActions>{' '}
            </Card>{' '}
          </Grid>{' '}
          <Grid xs={12} md={4} sm={4} className='CardPic'>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component='img'
                  height='400'
                  image={Card9}
                  alt='Gaurd'
                />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Event Security Management{' '}
                  </Typography>{' '}
                  <Typography variant='body2' color='black'>
                    {' '}
                  </Typography>{' '}
                </CardContent>{' '}
              </CardActionArea>{' '}
              <CardActions>
                <Link to='/SecurityServices' size='small'>
                  {' '}
                  Know More{' '}
                </Link>{' '}
              </CardActions>{' '}
            </Card>{' '}
          </Grid>{' '}
        </Grid>{' '}
        <br />
        <br /> {/* card with data mapping */}{' '}
        {/* <Grid container spacing={5} style={{ marginTop: '20px' }}>
                                                                      {' '}
                                                                      {Data.map((result, index) => (
                                                                        <Grid item xs={12} md={4} sm={4} key={index}>
                                                                          <Card sx={{ maxWidth: 345 }}>
                                                                            <CardActionArea>
                                                                              <CardMedia
                                                                                component='img'
                                                                                height='350'
                                                                                image={result.img}
                                                                                alt='green iguana'
                                                                              />{' '}
                                                                              <CardContent>
                                                                                <Typography gutterBottom variant='h5' component='div'>
                                                                                  {' '}
                                                                                  {result.title}{' '}
                                                                                </Typography>{' '}
                                                                                <Typography variant='body2' color='text.secondary'>
                                                                                  {' '}
                                                                                  {result.description}{' '}
                                                                                </Typography>{' '}
                                                                              </CardContent>{' '}
                                                                            </CardActionArea>{' '}
                                                                            <CardActions>
                                                                              {' '}
                                                                              {/* <Button size='small'> Share </Button>{' '} */}{' '}
        {/* <Link size='small'> Learn More </Link>{' '}
                                                                            </CardActions>{' '}
                                                                          </Card>{' '}
                                                                        </Grid>
                                                                      ))}{' '}
                                                                    </Grid>{' '} */}{' '}
        {/* * /}{' '} */}{' '}
      </Container>{' '}
    </Layout>
  )
}

export default SecurityServices
