import React from 'react'
import Layout from '../components/Layout/Layout'
import { Button, Container, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import Banner from '../Images/Home.jpeg'
import '../styles/HomeStyles.css'
// import Data from './DataH.json'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import { CardActionArea, Grid, CardActions } from '@mui/material'
import Marquee from 'react-fast-marquee'

import Card1 from '../Images/Pics/Card1.jpeg'
import Card2 from '../Images/Pics/Card2.jpeg'
import Card3 from '../Images/Pics/Card3.jpeg'

import img1 from '../Images/Clients/Jayaswal Neco Industries Limited.png'
import img2 from '../Images/Clients/Gaurav Electricals.png'
import img3 from '../Images/Clients/Venika Hydro Projects Pvt Ltd..png'
import img4 from '../Images/Clients/APL APOLLO TUBES LTD..jfif'
import img5 from '../Images/Clients/NTPC.png'
import img6 from '../Images/Clients/Bajaj Township.png'
import img7 from '../Images/Clients/R K Transport & Construction Ltd..png'
import img8 from '../Images/Clients/Shaksham Food Industries.png'
import img9 from '../Images/Clients/U B Ventures Pvt Ltd..png'
import img10 from '../Images/Clients/VGR Real Estate (Metro Green’s).png'

const Home = () => {
  return (
    <Layout>
      <div className='home' style={{ backgroundImage: `url(${Banner})` }}>
        <div className='headerContainer'>
          <h1> MADHYA BHARAT SECURITY SERVICE </h1>{' '}
          <Marquee pauseOnHover speed={70} width='10%' direction='right'>
            <p> Helping Keep You Safe </p>{' '}
          </Marquee>{' '}
          <Link to='/ContactUs'>
            {' '}
            <Button type='submit' variant='contained'>
              {' '}
              REQUEST A QUOTE{' '}
            </Button>{' '}
          </Link>{' '}
        </div>{' '}
      </div>{' '}
      <Container
        sx={{
          my: 0,
          textAlign: 'center',
          p: 2,
          '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
          '& p': { textAlign: 'justify' },
          '@media (max-width:600px)': {
            mt: 0,
            '& h4': {
              fontSize: '1rem',
            },
          },
        }}
      >
        <Marquee pauseOnHover speed={80}>
          <div className='headerContainer1'>
            {' '}
            <h1> WELCOME TO MADHYA BHARAT SECURITY SERVICE </h1>{' '}
          </div>{' '}
        </Marquee>{' '}
        <br /> <br />
        <Typography>
          <h2> Company Profile </h2>{' '}
        </Typography>{' '}
        <br />
        <p className='Home-heading'>
          {' '}
          MADHYA BHARAT SECURITY SERVICE is a professionally managed
          organization engaged as security services provider, and progressed
          role player within the security industry.MBSS is committed toward
          equal opportunity and affirmative action initiatives.We extend our
          services to Industrial security, educational institute, Commercial
          Complex, Mines, Hospital and residential complex.We deploy trained
          Security personnel.{' '}
        </p>{' '}
        <br />
        <p className='Home-heading'>
          Our team is consisting of well trained, professional and good
          communicated persons.Madhya Bharat Security Service is a registered
          agency under the shop and establishment, Labor department State
          Government of Chhattisgarh.{' '}
        </p>{' '}
        <br />
        <Typography variant='h3' color='#ff1744'>
          {' '}
          Our Services{' '}
        </Typography>{' '}
        <br />
        <Grid
          container
          spacing={5}
          style={{
            marginTop: '20px',
            className: 'CardPic',
            marginLeft: '5px',
            textAlign: 'center',
            '@media (max-width:600px)': {
              mt: 0,
              '& h4': {
                fontSize: '0.7rem',
                width: '100%',
                marginLeft: 5,
                paddingLeft: 7,
              },
            },
          }}
        >
          <Grid xs={12} md={4} sm={4} className='CardPic'>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component='img'
                  height='400'
                  image={Card1}
                  alt='Gaurd'
                />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    {' '}
                    Armed Gaurds Gun Man{' '}
                  </Typography>{' '}
                  <Typography variant='body2' color='black'>
                    {' '}
                  </Typography>{' '}
                </CardContent>{' '}
              </CardActionArea>{' '}
              <CardActions>
                <Link to='/SecurityServices' size='small'>
                  {' '}
                  Know More{' '}
                </Link>{' '}
              </CardActions>
            </Card>{' '}
          </Grid>{' '}
          <Grid xs={12} md={4} sm={4} className='CardPic'>
            <Grid item>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height='400'
                    image={Card2}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      {' '}
                      Un-Armed Gaurds{' '}
                    </Typography>{' '}
                    <Typography variant='body2' color='black'>
                      {' '}
                    </Typography>{' '}
                  </CardContent>{' '}
                </CardActionArea>{' '}
                <CardActions>
                  <Link to='/SecurityServices' size='small'>
                    {' '}
                    Know More{' '}
                  </Link>{' '}
                </CardActions>
              </Card>{' '}
            </Grid>{' '}
          </Grid>
          <Grid xs={12} md={4} sm={4} className='CardPic'>
            <Grid item>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height='400'
                    image={Card3}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      {' '}
                      Security Gaurds/Caretakers{' '}
                    </Typography>{' '}
                    <Typography variant='body2' color='black'>
                      {' '}
                    </Typography>{' '}
                  </CardContent>{' '}
                </CardActionArea>{' '}
                <CardActions>
                  <Link to='/SecurityServices' size='small'>
                    {' '}
                    Know More{' '}
                  </Link>{' '}
                </CardActions>
              </Card>{' '}
            </Grid>{' '}
          </Grid>
        </Grid>
        {/* Card data is used */}
        {/* <Grid
          container
          spacing={5}
          style={{ marginTop: '20px' }}
          className='CardPic'
        >
          {' '}
          {Data.map((result, index) => (
            <Grid item xs={12} md={4} sm={4} key={index}>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height='350'
                    image={result.img}
                    alt='green iguana'
                  />{' '}
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      {' '}
                      {result.title}{' '}
                    </Typography>{' '}
                    <Typography variant='body2' color='text.secondary'>
                      {' '}
                      {result.description}{' '}
                    </Typography>{' '}
                  </CardContent>{' '}
                </CardActionArea>{' '}
                <CardActions>
                  {' '}
                  {/* <Button size='small'> Share </Button>{' '} */}{' '}
        {/* <Link to='/SecurityServices' size='small'>
          {' '}
          Learn More{' '}
        </Link>{' '} */}
        {/* </CardActions>{' '}
              </Card>{' '}
            </Grid>
          ))}{' '}
        </Grid>{' '} */}{' '}
        {/* */}
        <br />
        <br />
        <br />
        <Typography variant='h3' color='#ff1744'>
          {' '}
          Our Clients{' '}
        </Typography>{' '}
        <br /> <br />
        <div>
          <Marquee pauseOnHover speed={100}>
            <Link to='/Clients' size='small'>
              {' '}
              <div className='image_wrapper'>
                <img src={img1} alt='' />
              </div>{' '}
            </Link>{' '}
            <Link to='/Clients' size='small'>
              {' '}
              <div className='image_wrapper'>
                <img src={img2} alt='' />
              </div>{' '}
            </Link>{' '}
            <Link to='/Clients' size='small'>
              <div className='image_wrapper'>
                <img src={img5} alt='' />{' '}
              </div>{' '}
            </Link>{' '}
            <Link to='/Clients' size='small'>
              <div className='image_wrapper'>
                <img src={img4} alt='' />{' '}
              </div>{' '}
            </Link>{' '}
            <Link to='/Clients' size='small'>
              <div className='image_wrapper'>
                <img src={img3} alt='' />{' '}
              </div>{' '}
            </Link>{' '}
            <Link to='/Clients' size='small'>
              <div className='image_wrapper'>
                <img src={img6} alt='' />{' '}
              </div>{' '}
            </Link>{' '}
            <Link to='/Clients' size='small'>
              <div className='image_wrapper'>
                <img src={img7} alt='' />{' '}
              </div>{' '}
            </Link>{' '}
            <Link to='/Clients' size='small'>
              <div className='image_wrapper'>
                <img src={img8} alt='' />{' '}
              </div>{' '}
            </Link>{' '}
            <Link to='/Clients' size='small'>
              <div className='image_wrapper'>
                <img src={img9} alt='' />{' '}
              </div>{' '}
            </Link>{' '}
            <Link to='/Clients' size='small'>
              <div className='image_wrapper'>
                <img src={img10} alt='' />{' '}
              </div>{' '}
            </Link>{' '}
          </Marquee>{' '}
        </div>{' '}
      </Container>{' '}
    </Layout>
  )
}

export default Home
