import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Layout from '../components/Layout/Layout'
import Banner from '../Images/Pics/AboutN.jpeg'
import { Container } from '@mui/material'

function About() {
  return (
    <Layout>
      <div className='home1' style={{ backgroundImage: `url(${Banner})` }}>
        {' '}
      </div>{' '}
      <Container
        Container
        sx={{
          my: 0,
          textAlign: 'center',
          p: 2,
          '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
          '& p': { textAlign: 'justify' },
          '@media (max-width:600px)': {
            mt: 0,
            '& h4': {
              fontSize: '1rem',
              my: 0,
            },
          },
        }}
      >
        <Typography variant='h4'> ABOUT US </Typography> <br />
        <p className='about-p'>
          We would provide the excellent Security Service to the satisfaction of
          your management and full Security coverage in your site. We are
          committed to quality and laid out standard. And we maintain statutory
          records and follow the rules of security services as per State Govt.
          notification.
        </p>
        <br />
        <ul className='About-ul'>
          <li>
            Our origination’s function is run by proper channel and security
            service guide lines with an absolute command and control structure.
            Our field officers are well experienced in the art of discipline.
          </li>
          <li>
            We have with us the trained and expert field officers, staffs and
            supervisors. Our officers regularly visit the sites to ensure better
            functioning and communication.
          </li>
          <li>
            We are ready to provide additional security personnel at very short
            notice to our regular and trusted clients.
          </li>
          <li>
            Our staff and security personnel are not member of any union. And
            are honest, loyal, disciplined, and presentable at all hours in all
            respects.
          </li>
          <li>
            The wages paid to our security person is according to the “Minimum
            Wages” as per government notification.
          </li>
          <li>In addition we provide the “DOG ESCORTS” as per requirement.</li>
          <li>
            We do not deploy any local person (Guards), we totally employ in our
            security, guards from other state.
          </li>
          <li>
            The contract will be accepted for minimum period of one year. If in
            the first work periods, our services are found as per satisfaction
            of the management, the contract may be renewed to the desired
            provider of management.
          </li>
        </ul>
        <br />
        <br />
        <div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Company Profile
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p className='About-accord'>
                {' '}
                "MADHYA BHARAT SECURITY SERVICE is a professionally managed
                organization engaged as security services provider, and
                progressed role player within the security industry.MBSS is
                committed toward equal opportunity and affirmative action
                initiatives.We extend our services to Industrial security,
                educational institute, Commercial Complex, Mines, Hospital and
                residential complex.We deploy trained Security personnel."{' '}
              </p>{' '}
              <br />
              <p className='About-accord'>
                "Our team is consisting of well trained, professional and good
                communicated persons.Madhya Bharat Security Service is a
                registered agency under the shop and establishment, Labor
                department State Government of Chhattisgarh."{' '}
              </p>{' '}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Our Vision
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p className='About-accord'>
                "We believe that perfect security is a target that can be
                achieved through trust and team work and field monitoring all of
                which are elements of professionalism. Your safety and peace of
                mind is the main aim which we strive to secure at all times
                through a strategy focused on trust, professionalism &
                integrated services."
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Our Mission
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p className='About-accord'>
                "We are the source of inspiration and delight to our customers
                by providing committed and motivated workforce with value
                addition to our service by a system of continuous feedback and
                analysis."
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Our Environment
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p className='About-accord'>
                "We offer a healthy work environment, which starts at training
                in which professionals knowledge is imparted to ensure pride in
                job and better understanding of situations that are encountered
                in this field. The policy of human welfare is followed within
                the framework of laws of the land."
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                House Keeping
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p className='About-accord'>
                "Our trained personnel for maintenance of offices, corporate
                clients, hospitals and banking sectors are available for
                providing housekeeping support."
              </p>
              <p className='About-accord'>
                Our team is motivated to perform under all circumstances and we
                are looking forward to provide you the best of services with
                emphasis on training followed by customized services based on
                system of feedback and analysis by personal interactions.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Company License Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul className='About-licence'>
                <li>Company Registration No. : 005228/RPR/S/2016</li>
                <li>
                  Company Labour Licence Registration No. : RPR/2023/44032565
                </li>
                <li>Company MSME Registration No. : UDYAM-CG-14-0030182 </li>
                <li>Company ISO 9001:2015 Registration No. : 305022111008Q</li>
                <li>Company Pan Registration No. : BIWPP2036G</li>
                <li>
                  Company Provident Fund Registration No. : CGRAI1557472000
                </li>
                <li>
                  Company ESIC Registration No.(Chhattisgarh) :
                  59001575650001099{' '}
                </li>
                <li>
                  Company ESIC Registration No.(Odisha) : 44591575650011099{' '}
                </li>
                <li>Company Pasara Registration No. : (04/17)/734/2022</li>
                <li>Company GST Registration No. : 22BIWPP2036G1Z6 </li>
                <li>Labour Welfare Registration No. : 27802 </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                General Manager / Operation Manager / Area Manager
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p className='About-accord'>
                "We can always locate area manager / supervisor close to our
                clients premises, so that daily liaison is established for
                effective command and control overall security. Our managers /
                supervisors organize monitor of the system and take necessary
                action immediately, if required."
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Recruitment
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p className='About-accord'>
                "To keep our standards and promise to provide the best
                professional security service, we recruit the best candidates.
                We give utmost attention to the recruitment process which
                follows our stringent corporate hiring rules. Each candidate has
                to undergo a rigorous physical fitness examination to gauge his
                tolerance, his ability to cope with difficult situations and his
                agility to act during emergencies. Also, their background is
                verified with the help of police. That’s not all, a candidate is
                only appointed when he is referred by a reliable source."
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Training
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p className='About-accord'>
                "After appointment all the candidates undergo a rigorous
                training for a month by highly trained professionals who have
                retired from top posts in military, police and fire service
                departments. They are given theoretical and practical lesions on
                basic guarding skills, rudiments on first aid, firefighting and
                self-defense. They are also given classes on courtesy,
                obedience, mannerism, fire prevention, disaster management and
                human psychology, to name a few."
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Supervision
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p className='About-accord'>
                "Our security personnel are supervised by area officers and area
                managers who frequent during day and night for surprise checks
                to ensure that everything is going smooth."
              </p>
            </AccordionDetails>
          </Accordion>
        </div>
      </Container>
    </Layout>
  )
}

export default About
