import React from 'react'
import Layout from '../components/Layout/Layout'

const Pagenotfound = () => {
  return (
    <Layout>
      <h1> Pagenotfound </h1>{' '}
    </Layout>
  )
}

export default Pagenotfound
